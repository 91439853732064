<template>
  <div class="lucky-container">
    <button @click="goBack" class="back-btn btn-primary">{{ $t('coin_treasure.return') }}</button>
    <div class="lucky-draw">
      <div class="count">{{ $t('lucky.count') }}: {{ count }}</div>
      <div class="draw-main">
        <div class="draw-pointer" @click="beginRotate()"></div>
        <div class="draw-bg" :style="rotateStyle" :class="`draw-` + prizeLength">
          {{ prizeList.lenght }}
          <div class="prize-list">
            <div class="prize-item" v-for="(item, index) in prizeList" :key="index" :style="item.style">
              <div class="prize-pic">
                <img :src="item.icon" />
              </div>
              <div class="prize-type">{{ item.name }} </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main">
        <div class="main-bg"></div>
        <div class="bg-p"></div>
        <div class="content">
          <!-- <div class="count">免费抽奖次数： {{ count }}</div> -->
        </div>
      <!-- <div class="tip">
          <div class="tip-title">活动规则</div>
          <div class="tip-content">
            <p>1.每日签到后，即可获得一次幸运大转盘的机会，仅限当天有效，过期作废。 2.金币抽奖，每10个金豆可兑换一次大转盘机会。</p>
            <p>2.金币抽奖，每10个金豆可以兑换一次大转盘抽奖机会</p>
            <p>3.所中金豆或积分到【我的账户】中查询。累计达到100金豆及以上，可以兑换相应奖品</p>
          </div>
        </div> -->
    </div>
    </div>
   
    <div class="lucky-toast" v-show="prize" @click="closeToast()">
      <div class="toast-container">
        <!-- <img :src="toastIcon" class="toast-picture" /> -->
        <div class="close" ></div>
        <div class="toast-title">{{ toastTitle }}</div>
        <div class="toast-btn">
          <div class="toast-cancel">{{ $t('lucky.close') }}</div>
        </div>
      </div>
    </div>
    <div class="toast-mask" v-show="prize"></div>
  </div>
</template>
<script>
import axios from 'axios';
import { getAppContext } from 'utilities/helper';
const CIRCLE_ANGLE = 360

const config = {
  // 总旋转时间
  duration: 4000,
  // 旋转圈数
  circle: 8,
  mode: 'ease-in-out'
}
let prizeList = [];

export default {
  data() {
    return {
      count: 0, // 剩余抽奖次数
      duration: 3000, // 转盘旋转时间
      prizeList: [], // 奖品列表
      rotateAngle: 0, // 旋转角度
      index: 0, //中奖下标
      prize: null,
      config: null,
      apiConfig: {
        options:[]
      },
      prizeLength: 0,
      context: null,
      hasCount: null,
    };
  },
  created() {
    // 如果活动禁用  返回首页
    if(!(this.sysconfig.luckydraw && this.sysconfig.luckydraw.enabled)) {
      this.$router.push({ path: '/'});
      $.top_alert(this.$t('lucky.overtime'));
      return false;
    }
    this.context = getAppContext();
    
    //用户未登录
    if(!this.context && !this.context.profile) {
      this.$router.push({ path: '/user/login', query: { from: this.$route.path } });
      return false
    }

    // 初始化一些值
    this.angleList = []
    // 是否正在旋转
    this.isRotating = false
    // 基本配置
    this.config = config;
    // 获取奖品列表
    this.initPrizeList();
  },
  computed: {
    rotateStyle() {
      return `
          -webkit-transition: transform ${this.config.duration}ms ${this.config.mode};
          transition: transform ${this.config.duration}ms ${this.config.mode};
          -webkit-transform: rotate(${this.rotateAngle}deg);
              transform: rotate(${this.rotateAngle}deg);`
    },
    toastTitle() {
      if(this.prize) {
        return this.$t('lucky.toast')+"  " +  this.prize.name;
      }
    },
    // toastIcon() {
    //   return this.prize && this.prize.isPrize === 1
    //     ? require("@/assets/images/lucky/congratulation.png")
    //     : require("@/assets/images/lucky/sorry.png");
    // }
  },
  methods: {
    goBack() {
        this.$router.go(-1);
    },
    async initPrizeList() {
      // 这里可以发起请求，从服务端获取奖品列表
      // 这里使用模拟数据
      const url = '/api/v1/luckydraw'
      const resp = await axios.get(g_server_root + url);
      const res = resp.data;
      if (res && res.errcode === 0) {
        const { state,config:dataConfig } = res.data;
        
        // 判断在不在活动时间范围
        const nowDate = new Date().getTime()
        if( ! (dataConfig.from < nowDate &&  nowDate < dataConfig.to) ) {
          this.$router.push({ path: '/'});
          $.top_alert(this.$t('lucky.overtime'));
          return false
        }

        this.apiConfig = dataConfig;
        this.prizeLength = dataConfig.options.length;
        // 1) participated 是否已经参与过当前抽奖
        // 2) open：抽奖窗口是否打开
        // 3) amount：如果已经参与过并且抽奖窗口打开，返回上次抽奖的金额
        // 4) currency: 上次参与抽奖的币种
        this.participated = state.participated
        if (!state.participated) {
          //用户没有参与抽奖 && 抽奖打开，设置1次抽奖
          this.count = 1;
        }
          prizeList = dataConfig.options.map(item => {
            return {
              icon: require("@/assets/images/lucky/gift.png"), // usdt 图片
              name: `${item}${this.apiConfig.currency}`, // 转盘显示内容
              isPrize: 1 // 设置1判断是否在中奖范围
            }
          })
          this.prizeList = this.formatPrizeList(prizeList)
      }


    },
    // 格式化奖品列表，计算每个奖品的位置
    formatPrizeList(list) {
      // 记录每个奖的位置
      const angleList = []
      const l = list.length
      // 计算单个奖项所占的角度
      const average = CIRCLE_ANGLE / l
      const half = average / 2
      // 循环计算给每个奖项添加style属性
      list.forEach((item, i) => {
        // 每个奖项旋转的位置为 当前 i * 平均值 + 平均值 / 2
        const angle = -((i * average) + half)
        // 增加 style
        item.style = `-webkit-transform: rotate(${angle}deg);
                        transform: rotate(${angle}deg);`
        // 记录每个奖项的角度范围
        angleList.push((i * average) + half)
      })

      this.angleList = angleList
      return list
    },
    async beginRotate() {
      // 添加次数校验
      if (this.participated) {
        $.top_alert(this.$t('lucky.again'))
        return
      }
      // 开始抽奖
      // 这里这里向服务端发起请求，得到要获得的奖
      // 可以返回下标，也可以返回奖品 id，通过查询 奖品列表，最终得到下标
      const url = '/api/v1/luckydraw'
      const resp = await axios.post(g_server_root + url);
      const res = resp.data;
      if (res && res.errcode === 0) {
        
        const { amount,currency,participated } = res.data
        //已经抽奖过的用户
        if(!participated) return false
        
        // 设置中奖的prizeList对应的下标
        this.index = this.findIndex(amount);
        console.log(amount);
        console.log("this.index",this.index);
        

        if(this.index < 0) {
          // 如果找不多
          console.error("no has config options key")
          return false 
        }
        // 减少剩余抽奖次数
        this.count--
        // 开始旋转
        this.rotating()
      }
    },
    findIndex(value) {
        const arr = this.apiConfig.options;
        // 将传入的值转换为具有两位小数的字符串
        // const valueStr = value.toFixed(2);
        const valueStr = value;
        // 遍历数组，找到与传入值相等的元素
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] === valueStr) {
            return i;
          }
        }
        // 如果没有找到匹配的值，返回 -1
        return -1;
    },
    random(max, min = 0) {
      return parseInt(Math.random() * (max - min + 1) + min)
    },
    rotating() {
      const { isRotating, angleList, config, rotateAngle, index } = this

      if (isRotating) return

      this.isRotating = true

      // 计算角度
      const angle =
        // 初始角度
        rotateAngle +
        // 多旋转的圈数
        config.circle * CIRCLE_ANGLE +
        // 奖项的角度
        angleList[index] -
        (rotateAngle % CIRCLE_ANGLE)


      this.rotateAngle = angle

      // 旋转结束后，允许再次触发
      setTimeout(() => {

        this.rotateOver()
      }, config.duration + 1000)
    },
    rotateOver() {
      this.isRotating = false
      this.prize = prizeList[this.index]
      console.log(this.prize, this.index)
    },
    //关闭弹窗
    closeToast() {
      this.prize = null;
    }
  }
};
</script>
<style scoped>
.lucky-container {
  width: 100%;
  height: calc(100vh - 120px);
  padding: 0;
  background-image: linear-gradient(#415EFD, #A267F1);
}
.back-btn {
  display: block;
  margin-top: 20px;
  margin-left: 20px;
  font-size: 13px;
}

.lucky-draw {
  padding-top: 120px;
  width: 100%;
  /* background: url('@/assets/images/lucky/bg.png') no-repeat center; */
  /* background-size: 100%; */
  /* background: rgb(252, 207, 133) url("@/assets/images/lucky/color_pillar.png") no-repeat center bottom; */
  /* padding-top: 20px; */
}
.lucky-draw .count {
  text-align: center;
  font-size: 14px;
  color: #fff;
  padding: 10px 0;
}



.draw-main {
  margin: 0 auto;
  position: relative;
  width: 320px;
  height: 320px;
}

.draw-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url("@/assets/images/lucky/p-4.png") no-repeat center top;
  background-size: 100%;
  color: #fff;
}
.draw-bg.draw-4 {
  background-image: url("@/assets/images/lucky/p-4.png");
}
.draw-bg.draw-6 {
  background-image: url("@/assets/images/lucky/p-6.png");
}
.draw-bg.draw-8 {
  background-image: url("@/assets/images/lucky/p-8.png");
}

.draw-pointer {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: 85px;
  height: 85px;
  background: url("@/assets/images/lucky/draw_btn.png") no-repeat center top;
  background-size: 100%;
  transform: translate3d(-50%, -50%, 0);
}

.draw-bg div {
  text-align: center;
}

.prize-list {
  width: 100%;
  height: 100%;
  position: relative;
}

.prize-list .prize-item {
  position: absolute;
  width: 95px;
  height: 155px;
  top: 0;
  left: 50%;
  margin-left: -47.5px;
  transform-origin: 50% 100%;
}

.prize-pic img {
  /* width: 4.0625rem; */
  height: 2.5rem;
  width: 2.5rem;
  margin-top: 2.5rem;
}

.prize-count {
  font-size: 0.875rem;
}

.prize-type {
  font-size: 0.75rem;
}

.main {
  position: relative;
  width: 100%;
  min-height: 14.25rem;
  padding-bottom: 1.6875rem;
}

.main-bg {
  /* width: 100%;
  height: 6.5625rem;
  position: absolute;
  top: -3.4375rem;
  left: 0;
  background: url("@/assets/images/lucky/luck_bg.png") no-repeat center top;
  background-size: 100%; */
}

.bg-p {
  width: 350px;
  max-width: 90%;
  position: relative;
  top: -20px;
  margin: 0 auto;
  height: 70px;
  background: url("@/assets/images/lucky/bg.png") no-repeat center center;
  background-size: 100%;
}

.content {
  position: absolute;
  top: 0.175rem;
  left: 0;
  width: 100%;
  height: 5.1875rem;
  font-size: 1.125rem;
  color: #fff;
  /* color: #ffeb39; */
}

.content div {
  text-align: center;
}

.tip {
  position: relative;
  margin: 2.5rem auto 0;
  width: 17.5rem;
  border: 1px solid #fbc27f;
}

.tip-title {
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 1rem;
  color: #fccc6e;
  background: rgb(243, 109, 86);
  padding: 0.3125rem 0.625rem;
}

.tip-content {
  padding: 1.5625rem 0.625rem;
  font-size: 0.875rem;
  color: #fff8c5;
  line-height: 1.5;
}

.toast-mask {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 8;
  width: 100%;
  height: 100%;
}

.lucky-toast {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9;
  transform: translate(-50%, -50%);
  width: 15.4375rem;
  background: #fff;
  border-radius: 0.3125rem;
  padding: 0.3125rem;
  background-color: rgb(252, 244, 224);
}

.toast-container {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px dotted #fccc6e;
}

.toast-picture {
  position: absolute;
  top: -6.5rem;
  left: -1.5rem;
  width: 18.75rem;
  height: 8.5625rem;
}

.toast-pictrue-change {
  position: absolute;
  top: -1.5rem;
  left: -1.375rem;
  width: 17.5rem;
  height: 3.125rem;
}

.toast-title {
  padding: 2.8125rem 0;
  font-size: 18px;
  color: #fc7939;
  text-align: center;
}

.toast-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.9375rem;
}

.toast-btn div {
  background-image: -moz-linear-gradient(-18deg,
      rgb(242, 148, 85) 0%,
      rgb(252, 124, 88) 51%,
      rgb(252, 124, 88) 99%);

  background-image: -ms-linear-gradient(-18deg,
      rgb(242, 148, 85) 0%,
      rgb(252, 124, 88) 51%,
      rgb(252, 124, 88) 99%);
  background-image: -webkit-linear-gradient(-18deg,
      rgb(242, 148, 85) 0%,
      rgb(252, 124, 88) 51%,
      rgb(252, 124, 88) 99%);
  box-shadow: 0px 4px 0px 0px rgba(174, 34, 5, 0.7);
  border-radius: 1.875rem;
  text-align: center;
  color: #fff;
  padding: 5px 10px;
}

.close {
  position: absolute;
  top: -0.9375rem;
  right: -0.9375rem;
  width: 2rem;
  height: 2rem;
  background: url("@/assets/images/lucky/close_store.png") no-repeat center top;
  background-size: 100%;
  z-index: 20000;
}
.toast-cancel {
  position: relative;
  z-index: 20000;
}
</style>