<template>
    <div class="login container">
        <div class="img-box-md d-block d-md-none">
            <img
                class="img-award-md"
                src="@/assets/images/id-leed/Right2x.png"
                alt=""
            />
        </div>
        <div class="login-inner" id="register-page">
            <form :class="{ 'd-none': !showInputs }" class="login-form" method="post" action="javascript:void(0);" @submit="createAccount">
                <h1 class="form-title">{{ $t('register.title') }}</h1>
                <!--
                Does both phone number and email are supported?
                -->
                <div class="page-top-nav mb-3" v-if="config.phoneSupported && config.emailSupported">
                    <li :class="{ active: !emailVCode }">
                        <a @click="emailVCode = false" href="javascript:;">{{ $t('register.label_phone') }}</a>
                    </li>
                    <li :class="{ active: emailVCode }">
                        <a @click="emailVCode = true" href="javascript:;">{{ $t('register.label_email') }}</a>
                    </li>
                </div>

                <div class="form-group">
                    <label class="form-label" for="UserName">{{ emailVCode?$t('register.label_email'):$t('register.label_phone') }}</label>
                    <template v-if="emailVCode">
                        <!-- Email account -->

                        <!-- Required by the interface -->
                        <input v-model.trim="account" name="Name" type="text" autocomplete="off" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^[^@]+@[^@]{3,}$" class="form-control" />
                    </template>
                    <template v-else>
                        <!-- Phone account -->

                        <div class="d-flex">
                            <region-selector :regions="regions" :regionCode="regionCode" @change="onRegionChanged" />
                            <div class="flex-fill">
                                <input v-model.trim="account" name="Name" type="text" autocomplete="off" pattern="\d*" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^0?[1-9]\d+$" class="form-control round-right" />
                            </div>
                        </div>
                    </template>
                </div>
                <div class="form-group">
                    <label class="form-label" for="Password">{{ $t('register.label_password') }}</label>
                     <div class="password-box">
                        <input name="Password" v-model.trim="password" :type="showPassword?'text':'password'" autocomplete="new-password" class="form-control" data-val="true" data-val-required="*" data-val-maxlength="*" data-val-maxlength-max="32" data-val-minlength="*" data-val-minlength-min="2" />
                        <svg v-show="!showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                        </svg>
                        <svg v-show="showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                        </svg>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label" for="ConfirmPassword">{{ $t('register.label_confirm_password') }}</label>
                     <div class="password-box">
                        <input name="ConfirmPassword" v-model.trim="confirmPassword" :type="showConfirmPassword?'text':'password'" autocomplete="new-password" class="form-control" data-val="true" data-val-required="*" data-val-equalto="*" data-val-equalto-other="Password" data-val-maxlength="*" data-val-maxlength-max="32" data-val-minlength="*" data-val-minlength-min="6" />
                        <svg v-show="!showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                        </svg>
                        <svg v-show="showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                        </svg>
                    </div>
                </div>
                <div class="form-group" v-if="emailVCode">
                    <label class="form-label" for="Phone">{{ $t('register.bind_phone') }}</label>
                    <div class="d-flex">
                        <region-selector :regions="regions" :regionCode="regionCode" @change="onRegionChanged" />
                        <div class="flex-fill">
                            <input v-model="Phone" name="Phone" type="text" autocomplete="off" pattern="\d*" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^0?[1-9]\d+$" class="form-control round-right" />
                        </div>
                    </div>                
                </div>
                <div class="form-group">
                    <label class="form-label" for="ReferralCode">{{ $t('register.label_referral_code') }}</label>
                    <div class="code">
                        <input type="text" name="ReferralCode" v-model="refCode" autocomplete="off" class="form-control" data-val="true" data-val-required="*" />
                    </div>
                </div>

                <!-- Do we need SMS verification? or just use a simple image verification code? -->
                <sms-verification v-if="config.signupVCodeRequired" :emailVCode="emailVCode" :regionCode="regionCode" :account="account" action="signup" />
                <template v-else>
                    <!-- Use a dummy sms code to make the API happy. -->
                    <input type="hidden" name="Code" value="000000" />
                </template>

                <!-- <button class="btn btn-submit" type="submit">{{ $t('register.label_next_step') }}</button> -->
                <button type="submit" class="btn btn-submit" :class="{ 'btn-completed': completed, 'btn-loading': submitting }" :disabled="submitting || completed">
                    {{ $t('register.label_register') }}
                </button>
                <div class="text-center form-group">
                    <router-link to="/user/login">{{ $t('register.label_login') }}</router-link>
                </div>
            </form>
        </div>
        <div class="img">
            <img src="@/assets/images/id-leed/Right2x.png" alt="">
        </div>
    </div>
</template>

<script>
import RegionSelector from '../../Components/RegionSelectorV2.vue';
import { setProfile } from 'utilities/helper';
import '@/assets/svg/v4.2/v4.2-hidePassword.svg';
import '@/assets/svg/v4.2/v4.2-showPassword.svg';
export default {
    components: { RegionSelector },
    props: ['config', 'regions', 'referral'],

    data() {
        return {
            regionCode: null,
            account: null,
            password: null,
            confirmPassword: null,
            refCode: null,
            showInputs: true,

            // Submit status
            submitting: false,
            completed: false,

            // which types of accounts are supported?
            emailVCode: false,
            Phone:null,
            showPassword:false,
            showConfirmPassword:false
        };
    },

    created() {
        const config = this.config;
        if (!config || config.signupEnabled !== true) throw new Error('signupEnabled must be true when creating new accounts.');

        const regions = this.regions;
        if (!Array.isArray(regions)) throw new Error('An array of supported regions are required.');

        this.refCode = this.referral;
    },

    watch: {
        referral: function () {
            this.refCode = this.referral;
        },

        emailVCode: function () {
            this.resetAll();
            Vue.nextTick(() => {
                $.resetValidators();
            });
        }
    },

    mounted() {
        $.resetValidators();
    },

    methods: {
        resetAll: function() {
            this.account=null;
            this.password=null;
            this.confirmPassword=null;
            this.refCode=null;
            this.Phone=null;
        },
        createAccount: function (e) {
            const frm = $(e.target);
            if (this.password !== this.confirmPassword) {
                $.top_error(this.$t("withdraw_password.err_different_passwords"));
                return;
            }
            if (!frm.valid()) return false;

            // post the server
            const data = frm.serialize();
            const self = this;
            self.submitting = true;

            $.callPostApi(self, '/api/v1/account/create', data)
                .then((json) => {
                    if (json && json.errcode === 0) {
                        try {
                            // Save the current authenticated user.
                            setProfile(json.data);

                            // redirect to home page
                            self.completed = true;

                            setTimeout(function () {
                                self.$router.push('/user/verifyid');
                            }, 500);
                        } catch (err) {
                            console.error(err);
                        }
                    }else if(json.errcode === 103){
                        // 什么也不做，保持loading状态
                    } else {
                        self.submitting = false;
                        $.top_error(json.errmsg);
                    }
                })
                .catch(function (err) {
                    self.submitting = false;
                    $.top_error(self.$t('general.operation_error'));
                })
        },

        onRegionChanged: function (val) {
            console.log(`## Region changed to ${val}`);
            this.regionCode = val;
        }
    }
};
</script>
<style scoped>
.container .img-box-md {
    width: 100%;
    margin-top: -20px;
}
.img-award-md {
    width: 100%;
    height: auto;
}
.login-page .login h1{
    font-size: 26px;
}
.container{
    width: 100%;
    /* height: 100vh; */
    margin: 0;
    max-width: 100%;
    display: flex;
}
.container>div{
    width: 50%;
    height: initial;
    min-width: 540px;
}
.img{
    text-align: center;
    background-color: #060505;
    display: flex;
    align-items: center;
}
.img img {
    width: 100%;
}
.right-content{
    background: #000;
    position: relative;
}
.img-award{
    position: absolute;
    top: -162px;
    right: 20%;
}
.form-title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 26px;
    color: #000000;
}
.login-page .login .login-inner {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#register-page >.login-form{
    width: 100%;
    min-width: 300px;
    max-width: 700px;
    padding: 0 15%;
}
.page-top-nav{
    display: flex;
    align-items: center;
    margin-bottom: 40px !important;
}
.page-top-nav li{
    width: 50%;
    padding: 0;
    text-align: center;
    box-sizing: border-box;
}
.page-top-nav li.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 4.25rem;
    height: .225rem;
    background-color: #11cf8b;
}
.page-top-nav li a {
    display: inline-block;
    padding: 0.5rem 0.75rem;
    height: 100%;
}
.page-top-nav li a {
    color: #9ea5b2;
    font-size: 18px;
}
.page-top-nav li.active {
    position: relative;
}
.page-top-nav li.active a {
    color: #11cf8b;
    /* border-bottom: 2px solid #041438; */
    font-size: 24px;
    font-weight: 600;
}
.form-label{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #aaa;
}
.page button.btn-submit{
    background: #11cf8b;
    border-radius: 8px 8px 8px 8px;
    margin: 40px 0 20px;
    color: #000;
    font-weight: 600;
}
.text-center a{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #11cf8b;
}
.form-control{
    background: #fff;
}
.login-page .login .form-control{
    background: #272727;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #C8C8D0;
}
.login-page .login .form-control:focus{
    box-shadow: 0px 0px 12px 0px rgba(82,53,232,0.2);
    border: 1px solid #C8C8D0;
}
.right-content{
    width: 320px;
    min-height: 550px;
    display: inline-block;
    margin-top: 200px;
    padding: 15px;
}
.right-title{
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    border-bottom: 1px solid #D8D8D8;
    min-height: 40px;
    margin-top: 110px;
}
.right-boby>div{
    text-align: left;
    padding: 2px 10px;
}
.right-boby{
    margin-top: 10px;
}
.right-boby>div:nth-child(2){
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #2C2236;
}
.right-boby>div:nth-child(3){
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    color: #2C2236;
}
.password-box {
    position: relative;
}
.password-icon {
    position: absolute;
    right: 15px;
    bottom: 50%;
    transform: translateY(50%);
    cursor: pointer;
}
.login {
    padding: 0;
    flex: 1;
}
@media (max-width: 768px) {
    .login-page .login .login-inner{
        justify-content: center;
    }
    .login.container {
        display: block;
    }
    .login-page .login .form-control {
        background: #FAFAFE;
        color: #000;
        /* border: none; */
    }
    .login-page .login .form-control:focus {
        /* border: none; */
    }
    #register-page {
        margin-top: -110px;
        background-color: #11cf8b;
        position: relative;
        z-index: 9;
        border-radius: 30px;
        padding-bottom: 60px;
    }
    #register-page .login-form {
        background-color: #fff;
        margin-top: 10px;
        border-radius: 30px;
        padding-top: 40px;
        max-width: none;
        padding: 0 20px;
        padding-top: 40px;
    }
    .page button.btn-submit {
      background-color: #161B27;
      color: #fff;
    }
}
@media (max-width: 1024px) {
    .login-page .login h1{
        margin-top: 0px;
    }
    .img{
        display: none;
    }
    .container>div{
        min-width: 300px;
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        background: #000;
   }
   .login-page .login h1{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 26px;
        color: #3D3D3D;
        padding: 0px;
   }
   .login-page .login .login-inner {
        width: 100%;
   }
}
</style>
