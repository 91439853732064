<template>
    <section class="page posts-page">
        <div class="container">
            <div class="row">
                <div class="col">
                    <!-- <h1>{{ title }}</h1> -->
                    <loading-indicator v-if="!categories" />
                </div>
            </div>
            <div class="row">
                <loading-indicator v-if="!posts" />
                <posts-component :posts="posts" />
            </div>
        </div>

    </section>
</template>

<script lang="js">
import PostsComponent from './Components/_Posts.vue';

export default {
    components: { PostsComponent },
    props: ['id'],

    data() {
        return {
            active_id: null,
            // title: null,
            posts: null,
            categories: null
        };
    },

    watch: {
        $route() {
            this.initPageAsync(this.id);
        },

        /**
         * Update post content after the language is changed.
         */
        '$i18n.locale': function (newVal, oldVal) {
            this.initPageAsync(this.id);
        }
    },

    mounted() {
        this.initPageAsync(this.id);
    },

    methods: {
        initPageAsync: async function (id) {
            this.active_id = id;
            // this.title = null;
            this.posts = null;

            const json = await $.callPostApi(this, '/api/v1/post/category?id=' + encodeURIComponent(id));
            if (json && json.errcode === 0 && json.data) {
                // this.title = json.data.category.name;
                this.posts = json.data.items;
                this.categories = json.data.categories;
            } else {
                this.$router.push('/notfound');
            }
        }
    }
};
</script>
<style scoped>
.page-top-nav {
    border-bottom: none;
}
</style>