<template>
    <div class="section">
        <div class="caption">{{$t('home.home_advantage')}}</div>
        <div class="section1 container">
            <div class="left" >
                <div class="left1">
                    <div class="img1"><img src="@/assets/images/id-leed/image1.png" /></div>
                    <div class="title">
                        <div class="title1">{{$t('home.home_advantage_title1')}}</div>
                        <div class="title2">{{$t('home.home_advantage_desp1')}}</div>
                    </div>
                </div>  
                <div class="left2">
                    <div class="img1"><img src="@/assets/images/id-leed/image2.png" /></div>
                    <div class="title">
                        <div class="title1">{{$t('home.home_advantage_title2')}}</div>
                        <div class="title2">{{$t('home.home_advantage_desp2')}}</div>
                    </div>
                </div> 
            </div>
            <div class="right" >
                <div class="right1">
                    <div class="img1"><img src="@/assets/images/id-leed/image3.png" /></div>
                    <div class="title">
                        <div class="title1">{{$t('home.home_advantage_title3')}}</div>
                        <div class="title2">{{$t('home.home_advantage_desp3')}}</div>
                    </div>
                </div> 
                <div class="right2">
                    <div class="img1"><img src="@/assets/images/id-leed/image4.png" /></div>
                    <div class="title">
                        <div class="title1">{{$t('home.home_advantage_title4')}}</div>
                        <div class="title2">{{$t('home.home_advantage_desp4')}}</div>
                    </div>
                </div> 
            </div>
        </div>
        <div class="section2 container">
            <div class="information" >
                <h2 class="title">{{$t('home.home_logo_title')}}</h2>
                <div class="imgs">
                    <img class="href-logo" src="@/assets/images/id-leed/bottomLogo1.png"/>
                    <img class="href-logo" src="@/assets/images/id-leed/bottomLogo2.png"/>
                    <img class="href-logo" src="@/assets/images/id-leed/bottomLogo3.png"/>
                    <img class="href-logo" src="@/assets/images/id-leed/bottomLogo4.png"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  components: {  },
    data() {
        return {
           
        }
    },
    created() { },
    methods: {

    }
}
</script>
<style lang="less" scoped>
.section{
    margin-top: 30px;
    .caption{
        font-family: PingFang HK, PingFang HK;
        font-weight: 500;
        font-size: 28px;
        color: #FFFFFF;
        text-align: center;
    }
    width: 100%;
    .section1{
        display: flex;
        width: 100%; 
        min-height: 400px;
        .left{
            width: 50%;
            display: flex;
            .left1,.left2{
                margin-right: 20px;
                padding: 10px;
                width: 50%;
                text-align: center;
                img{
                    width: 90%;
                    height: 240px;
                }
                .title1{
                    font-family: PingFang HK, PingFang HK;
                    font-weight: 500;
                    font-size: 20px;
                    color: #FFFFFF;
                }
                .title2{
                    font-family: PingFang HK, PingFang HK;
                    font-weight: 500;
                    font-size: 14px;
                    color: #aba7a7;
                }
            }
        }
        .right{
            width: 50%;
            display: flex;
            .right1{
                margin-right: 20px;
            }
            .right1,.right2{
                padding: 10px;
                width: 50%;
                text-align: center;
                img{
                    width: 90%;
                    height: 240px;
                }
                .title1{
                    font-family: PingFang HK, PingFang HK;
                    font-weight: 500;
                    font-size: 20px;
                    color: #FFFFFF;
                }
                .title2{
                    font-family: PingFang HK, PingFang HK;
                    font-weight: 500;
                    font-size: 14px;
                    color: #aba7a7;
                }
            }
        }
    }
    .information{
        margin-bottom: 30px;
        .title{
            text-align: center;
            font-family: PingFang HK, PingFang HK;
            font-weight: 500;
            font-size: 28px;
            color: #fff;
            margin-top: 20px;
        }
        .imgs{
            margin-top: 30px;
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
}



 



@media (max-width: 992px) {

}
@media (max-width: 1024px)
{   
.section{
    .information{
        .imgs{
            height: 35px;
        }
    }
    .section1{
        flex-direction: column;
        .left{
             .left1,.left2{
                img{
                    width: 90%;
                    height: 160px;
                }
            }
        }
        .right{
            .right1,.right2{
                img{
                    width: 90%;
                    height: 140px;
                }
            }
        }
    }
}    
@media (max-width: 1024px)
{ 
  .section{
    .section1{
        flex-direction: column;
        .left{
            width: 100%;
            .left2{
                margin: 0;
            }
        }
        .right{
            width: 100%;
            .right2{
                margin: 0;
            }
        }
    }
}  
}

 
}




</style>
